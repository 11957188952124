/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/smoothstate@0.7.2/src/jquery.smoothState.min.js
 * - /npm/nprogress@0.2.0/nprogress.min.js
 * - /npm/little-loader@0.2.0/dist/little-loader.min.js
 * - /npm/waypoints@4.0.1/lib/jquery.waypoints.min.js
 * - /npm/waypoints@4.0.1/lib/shortcuts/sticky.min.js
 * - /npm/particles.js@2.0.0/particles.min.js
 * - /npm/little-loader@0.2.0/dist/little-loader.min.js
 * - /npm/cookieconsent@3.0.6/build/cookieconsent.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
